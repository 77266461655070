/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/


.App {
  text-align: center;
  height: 100%;
}

html {
  height: 100%;
  background: linear-gradient(315deg, rgb(77,126,126),rgb(70,85,114));
  background-color: var(--main-bg-color);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#root {
  height: 100%;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:root {
  --main-bg-color: #fefefe;
  --hero-color: rgb(52, 68, 88);
  --hero-highlight: #F18B05;
  --hero-orange: #fd974f;
  --hero-orange-light: rgba(255, 255, 255, 0.25);
  --active-color: #2f9aef;
  --piano-color: #2f9aef;
  --guitar-color: #f0c229;
  --marimba-color: #f6843f;
  --sequencer-color: #f6843f;
  --bass-color: #ec5eb2;
  --drum-color: #86c44e;
  --sound-color: lightseagreen;
  --text-color: #30acac;
  --definitions-color: #996dd9;
  --header-bg-color: #2e303f;
  --mixer-track-pad: 15px;
  --error-color: #da575d;
  --main-font: 'Nunito', sans-serif;
  --title-font: 'Nunito Sans', sans-serif;
  --impact-font: 'Alegreya Sans', sans-serif;
  --impact-font-alt: 'Oleo Script', cursive;
  --nav-color: #414251;
  --main-border-radius-5: 5px;
  --main-border-radius-7: 7px;
  --main-box-shadow: 0px 15px 5px -10px rgb(50 50 93 / 25%), 0 30px 65px -30px rgb(0 0 0 / 0%);
  --project-box-shadow: 0px 15px 5px -10px rgb(50 50 93 / 25%), 0 30px 65px -30px rgb(0 0 0 / 0%);
  --background-pattern: radial-gradient(circle at top left, rgb(235, 235, 235) 0%, rgb(235, 235, 235) 10%, rgb(229, 229, 229) 10%, rgb(229, 229, 229) 18%, rgb(222, 222, 222) 19%, rgb(222, 222, 222) 21%, rgb(216, 216, 216) 22%, rgb(216, 216, 216) 23%, transparent 23%), radial-gradient(circle at top left, rgb(209, 209, 209) 23%, rgb(209, 209, 209) 66%, rgb(205, 205, 205) 67%, rgb(205, 205, 205) 75%, rgb(200, 200, 200) 74%, rgb(200, 200, 200) 75%, transparent 75%), radial-gradient(circle at top left, rgb(195, 195, 195) 74%, rgb(195, 195, 195) 84%, rgb(190, 190, 190) 85%, rgb(190, 190, 190) 85%, rgb(77, 126, 126) 85%, rgb(77, 126, 126) 86%, rgb(70, 85, 114) 93%, rgb(70, 85, 114) 100%);
  --alt-background-pattern: radial-gradient(circle at bottom right, rgb(235, 235, 235) 0%, rgb(235, 235, 235) 10%, rgb(229, 229, 229) 10%, rgb(229, 229, 229) 18%, rgb(222, 222, 222) 19%, rgb(222, 222, 222) 21%, rgb(216, 216, 216) 22%, rgb(216, 216, 216) 23%, transparent 23%), radial-gradient(circle at bottom right, rgb(209, 209, 209) 24%, rgb(209, 209, 209) 66%, rgb(205, 205, 205) 67%, rgb(205, 205, 205) 75%, rgb(200, 200, 200) 74%, rgb(200, 200, 200) 75%, transparent 75%), radial-gradient(circle at bottom right, rgb(195, 195, 195) 74%, rgb(195, 195, 195) 84%, rgb(190, 190, 190) 85%, rgb(190, 190, 190) 85%, rgb(77, 126, 126) 85%, rgb(77, 126, 126) 86%, rgb(70, 85, 114) 93%, rgb(70, 85, 114) 100%);
  --gray-background-pattern: radial-gradient(circle at 60% 60%, rgb(250, 250, 250) 0%, rgb(247, 247, 247) 6%, rgb(242, 242, 242) 6%, rgb(242, 242, 242) 15%, rgb(240, 240, 240) 15%, rgb(240, 240, 240) 47%, rgb(235, 235, 235) 47%, rgb(235, 235, 235) 54%, rgb(232, 232, 232) 54%, rgb(232, 232, 232) 56%, rgb(229, 229, 229) 56%, rgb(229, 229, 229) 90%, rgb(223, 223, 223) 90%, rgb(223, 223, 223) 100%);
  --background-admin-pattern: linear-gradient(67.5deg, rgb(215, 215, 215) 0%, rgb(215, 215, 215) 46%, rgb(189, 186, 186) 46%, rgb(189, 186, 186) 49%, rgb(181, 181, 181) 49%, rgb(181, 181, 181) 56%, rgb(164, 164, 164) 56%, rgb(164, 164, 164) 61%, rgb(146, 146, 146) 61%, rgb(146, 146, 146) 75%, rgb(129, 129, 129) 75%, rgb(129, 129, 129) 84%, rgb(112, 112, 112) 84%, rgb(112, 112, 112) 100%), linear-gradient(22.5deg, rgb(215, 215, 215) 0%, rgb(215, 215, 215) 46%, rgb(189, 186, 186) 46%, rgb(189, 186, 186) 49%, rgb(181, 181, 181) 49%, rgb(181, 181, 181) 56%, rgb(164, 164, 164) 56%, rgb(164, 164, 164) 61%, rgb(146, 146, 146) 61%, rgb(146, 146, 146) 75%, rgb(129, 129, 129) 75%, rgb(129, 129, 129) 84%, rgb(112, 112, 112) 84%, rgb(112, 112, 112) 100%), linear-gradient(112.5deg, rgb(215, 215, 215) 0%, rgb(215, 215, 215) 46%, rgb(189, 186, 186) 46%, rgb(189, 186, 186) 49%, rgb(181, 181, 181) 49%, rgb(181, 181, 181) 56%, rgb(164, 164, 164) 56%, rgb(164, 164, 164) 61%, rgb(146, 146, 146) 61%, rgb(146, 146, 146) 75%, rgb(129, 129, 129) 75%, rgb(129, 129, 129) 84%, rgb(112, 112, 112) 84%, rgb(112, 112, 112) 100%), linear-gradient(90deg, rgb(231, 231, 231), rgb(195, 195, 195));

  --background-pattern-blue: radial-gradient(circle at top left, rgb(235,235,235) 0%, rgb(235,235,235) 10%, rgb(229, 229, 229) 10%, rgb(229, 229, 229) 18%, rgb(222, 222, 222) 19%, rgb(222, 222, 222) 21%, rgb(216, 216, 216) 22%, rgb(216, 216, 216) 23%, transparent 23%), radial-gradient(circle at top left,rgb(209, 209, 209) 23%, rgb(209, 209, 209) 66%,rgb(205, 205, 205) 67%, rgb(205, 205, 205) 75%,rgb(200, 200, 200) 78%, transparent 78%), radial-gradient(circle at top left,rgb(195, 195, 195) 79%, rgb(195, 195, 195) 84%,rgb(77,126,126) 84%,rgb(77,126,126) 86%,rgb(70,85,114) 93%, rgb(70,85,114) 100%);
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: var(--main-font);
  font-weight: 200;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
}

main {
  padding-top: 80px;
  padding-left: 60px;
  min-height: 100%;
}

.content {
  height: 100%;
  min-height: 100vh;
  padding-top: 50px;
}

h1 {
  font-family: var(--impact-font);
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #FFF;
  line-height: 120%;
  max-width: 90vw;
  margin: 1em 1.5em 0 auto;
}

h2 {
  font-family: var(--main-font);
  font-size: 26px;
  font-weight: 600;
  color: var(--hero-color);
}

h3 {
  font-family: var(--main-font);
  font-size: 18px;
  font-weight: 600;
  color: var(--hero-color);
}

h1 .dark,
h2 .dark,
h1.dark, h2.dark {
  color: #465572;
}


/*
* Shared
*/

.hidden {
  visibility: hidden;
  display: none !important;
}

button {
  height: 32px;
  background-color: #3d4d60;
  padding: 0 3px;
  font-size: 15px;
  color: #fff;
  outline: none;
  font-size: 11pt;
  font-weight: 500;
  font-family: var(--main-font)!important;
  border: 2px solid transparent;
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  margin: 3px;
}
button:hover { cursor: pointer; }
button.rounded {
  border-radius: 24px;
}

button.default {
  background-color: #3d4d60;
  transition: background-color .175s ease-in-out;
}

button.default:hover {
  background-color: transparent;
  border: 2px solid #3d4d60;
  color: #3d4d60 !important;
  transition: background-color .175s ease-in-out;
}

button.secondary {
  background-color: #fff;
  color: rgba(52,68,88,.5);
  border: 1px solid rgba(52,68,88,.5);
  transition: opacity .175s ease-in-out;
  opacity: 1;
}

button.secondary:hover {
  transition: opacity .175s ease-in-out;
  opacity: 0.7;
}

button.danger {
  background-color: var(--error-color);
  color: #fff;
  transition: background-color .175s ease-in-out;
}

button.danger:hover {
  background-color: transparent;
  border: 2px solid var(--error-color);
  color: var(--error-color) !important;
  transition: background-color .175s ease-in-out;
}

button.danger-secondary {
  color: var(--error-color);
  border: 1px solid var(--error-color);
  background-color: #fff;
  opacity: 1;
  transition: opacity .175s ease-in-out;
}

button.danger-secondary:hover {
  transition: opacity .175s ease-in-out;
  opacity: 0.7;
}

button.confirm {
  background-color: var(--drum-color);
  color: #fff;
  transition: background-color .175s ease-in-out;
}

button.confirm:hover {
  background-color: transparent;
  border: 2px solid var(--drum-color);
  color: var(--drum-color) !important;
  transition: background-color .175s ease-in-out;
}

button:disabled {
  background-color: #ccc;
  color: #3d4d60 !important;
  border-color: #3d4d60;
  outline: none;
  cursor: not-allowed;
}
button:disabled:hover {
  background-color: #ccc;
  color: #3d4d60 !important;
  border-color: #3d4d60;
  outline: none;
  cursor: not-allowed;
}

button a {
  color: unset;
  text-decoration: none;
}

.profile-picture {
  min-width: 100px;
  min-height: 100px;
  background-color: #FFF;
  box-shadow: rgb(60 66 87 / 12%) 0px 7px 14px 0px, rgb(0 0 0 / 12%) 0px 3px 6px 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-origin: content-box;
  width: 100px;
  height: 100px;
  border-radius: 20%;
}
.profile-picture.small,
.default-user-picture.small {
  min-width: 35px;
  min-height: 35px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
}

.profile-picture.medium,
.default-user-picture.medium {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.default-user-picture {
  min-width: 100px;
  min-height: 100px;
  border: 2px #3D4D60 dotted;
  background-image: url('../resources/default_profile.svg');
  background-clip: padding-box;
  padding: 10px;
  left: 175px;
  border-radius: 20%;
}

.default-user-picture.small {
  padding: 3px;
}

#page-heading {
  overflow: hidden;
  margin: 0;
  padding-top: 77px;
  position: relative;
}

#page-heading:before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  opacity: 1;
  z-index: -1;
}

#page-heading-title {
  text-align: left;
  max-width: 90vw;
  height: auto;
}

#page-heading-title h1.secondary {
  margin-right: 1.5em;
}


.gadget-icon {
  min-width: 36px;
  min-height: 36px;
  background-color: var(--piano-color);
  border-radius: 50%;
  margin-right: 10px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-flex;
  position: relative;
}
.gadget-icon.guitar {
  background-color: var(--guitar-color);
  background-image: url('../resources/bass.svg');
}
.gadget-icon.bass {
  background-color: var(--bass-color);
  background-image: url('../resources/bass.svg');
}
.gadget-icon.marimba {
  background-color: var(--marimba-color);
  background-image: url('../resources/marimba.svg');
}
.gadget-icon.piano {
  background-color: var(--piano-color);
  background-image: url('../resources/synth.svg');
}
.gadget-icon.drums , .gadget-icon.drumkit {
  background-color: var(--drum-color);
  background-image: url('../resources/drumkit.svg');
}
.gadget-icon.definitions {
  background-color: var(--definitions-color);
  background-image: url('../resources/definitions.svg');
}

.gadget-icon .play-icon {
  /*visibility: hidden;*/
  opacity: 0;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.375);
  color: #f1f1f1;
  transition: all 0.175s ease-in-out;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
}

.library-item .gadget-icon .play-icon {
  font-size: 28pt;
}

.gadget-icon:hover .play-icon {
  opacity: 1;
  transition: all 0.175s ease-in-out;
}

.gadget-icon.definitions .play-icon {
  display: none;
}

.x-mark {
  stroke: black;
}

.tooltip-outer {
  display: flex;
  margin: 4px;
  margin-left: 8px;
  position: relative;
}

.tooltip-outer .tooltip-text {
  display: none;
  position: absolute;
  background: white;
  padding: 10px;
  opacity: 0.95;
  border-radius: 6px;
  left: 100%;
  bottom: 0;
  z-index: 100;
  width: 275px;
  margin-left: 3px;
}

.tooltip-icon:hover {
  opacity: 0.8;
  cursor: help;
}

.tooltip-outer:hover .tooltip-text {
  display: unset;
}

.tooltip-outer.reactions::before {
  display: none;
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -1px;
  height: 0px;
  width: 0px;
  border: none;
  border-top: 5px solid #f0f0f0;
  border-left: 5px solid rgba(0,0,0,0);
  border-right: 5px solid rgba(0,0,0,0);
  z-index: 10000;
}

.tooltip-outer.reactions:hover::before {
  display: unset;
}

.tooltip-outer.reactions .tooltip-text {
  position: absolute;
  opacity: 0;
  border-radius: 6px;
  bottom: 100%;
  z-index: 4000;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 16pt;
  width: unset;
  background: #ffffffeb;
  padding: 10px;
  margin-bottom: 1px;
  box-shadow: 0px 15px 5px -10px rgb(50 50 93 / 25%), 0 30px 65px -30px rgb(0 0 0 / 0%);
  transition: opacity 0.15s ease-out;
}

.tooltip-outer.reactions {
  margin: 0;
}

.tooltip-outer.reactions .tooltip-text {
  visibility: hidden;
  opacity: 0;
  display: unset;
  transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
}

.tooltip-outer.reactions:hover .tooltip-text {
  opacity: 0.95;
  visibility: visible;
}

.tooltip-outer.reactions .tooltip-text .icon {
  margin: 8px 5px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-outer.reactions:hover .tooltip-text .icon:hover {
  opacity: 0.7;
  transform: scale(1.1);
  cursor: pointer;
}

.tooltip-outer.reactions .tooltip-text .selected .icon {
  background-color: #2e303fa8;
  outline: 3px solid #2e303fa8;
  border-radius: 3px;
}

.reactions .outer-icon {
  width: 75%;
}

.reactions .icon.star {
  color: var(--drum-color);
}

.icon.love {
  color: var(--error-color);
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider.default, input:checked + .slider.secondary {
  background: var(--active-color);
}

input:focus + .slider.default, input:focus + .slider.secondary {
  box-shadow: 0 0 1px var(--active-color);
}

input:checked + .slider.confirm {
  background: var(--drum-color);
}

input:focus + .slider.confirm {
  box-shadow: 0 0 1px var(--drum-color);
}

input:checked + .slider.danger, input:checked + .slider.danger-secondary  {
  background: var(--error-color);
}

input:focus + .slider.danger, input:focus + .slider.danger-secondary {
  box-shadow: 0 0 1px var(--error-color);
}

input:disabled + .slider {
  background-color: var(--header-bg-color) !important;
  color: #3d4d60 !important;
  border-color: #3d4d60;
  cursor: not-allowed;
  opacity: 0.9;
}

input:disabled + .slider::before {
  background-color: #ccc !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.page-error {
  width: 100%;
  text-align: center;
  padding: 200px 5px;
  color: var(--main-bg-color);
  font-weight: 300;
}

.page-error h1 {
  margin: auto;
}

.page-error a {
  color: var(--sequencer-color);
  text-decoration: none;
}

.page-error a:hover {
  text-decoration: underline;
}

#empty-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  opacity: 0.65;
  overflow: hidden;
  padding: 25px;
  text-align: center;
}

#empty-icon {
  color: var(--hero-color);
  font-size: 70pt;
  opacity: 0.8;
}

.scroll-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  font-size: 20pt;
  background: tomato;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  transition: visibility 0s, opacity 0.15s linear;
  opacity: 1;
}

.scroll-top.hidden {
  transition: visibility 0s, opacity 0.15s linear;
  opacity: 0;
}

.scroll-top.visible:hover {
  opacity: 0.8;
  cursor: pointer;
}

.confirm-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: white;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 256px;
  right: calc(50% - 128px);
}

.confirm-actions {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.confirm-content button {
  width: 40px;
}



.confirm-content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.confirm-anchor {
  position: relative;
}

.confirm-anchor.show-content .confirm-content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popup {
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  position: fixed;
  top: 62px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--main-font) !important;
  color: var(--hero-color);
  padding: 15px;
  z-index: 5000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

.popup svg {
  margin-right: 5px;
  color: var(--piano-color);
  opacity: 0.85;
  font-size: 13pt;
}

.popup #icon\ success {
  color: var(--drum-color);
}

.popup #icon\ error {
  color: var(--error-color);
}

.modal {
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  padding-top: 35px;
}

.overlay {
  background-color: #000b;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal .modal-close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  font-size: 14pt;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal .modal-close:hover {
  opacity: 0.8;
}

#loading-dialog-empty {
  display: none;
}

.loading-wrapper {
  width: 100%;
}

.loading-dialog {
  position: relative;
  display: block;
  background-color: #fafafa;
  color: #333;
  font-size: 30px;
  line-height: 150%;
  font-weight: bold;
  box-sizing: border-box;
  margin: 100px auto;

  height: 200px;
  width: 200px;
  border-radius: 50%;
  padding: 20px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#date-outer {
  position: relative;
}

#date-outer #modified {
  transition: all 0.25s ease-in-out;
}

#date-outer #date-inner {
  display: none;
  position: absolute;
  background-color: var(--header-bg-color);
  color: white;
  font-weight: normal;
  padding: 2px 5px;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  top: -18px;
}

#date-outer:hover {
  text-decoration: underline;
  cursor: pointer;
}

#date-outer:hover #date-inner {
  display: unset;
}

.page-content .home-logo a {
  text-decoration: none;
  margin: 0px auto;
  color: var(--hero-color);
  font-weight: 600;
}

/***************/
/*** Nav Bar ***/
/***************/

nav .top-nav-wrapper {
  display: flex;
  width: 100%;
  max-width: 1920px;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

nav .top-nav-logo-wrapper {
  display: flex;
  align-items: center;
}

.top-nav-logo-wrapper .menu-icon {
  display: none;
}

nav.top-nav {
  background-color: #FFF;
  position: fixed;
  width: 100%;
  height: 55px;
  color: #2b344f;
  z-index: 5000;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  opacity: 0.95;
  padding: 0 60px 0 60px;
}

nav.top-nav a.home-logo {
  background-image: url('../resources/logo-blue.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  width: 50px;
  height: 50px;
  font-size: 0pt;
}

nav.top-nav a.home-logo:hover {
  background-size: 70%;
  background-image: url('../resources/logo-blue.svg');
  background-position: center;
}

nav.top-nav a {
  min-width: 60px;
  min-height: 36px;
  text-decoration: none;
  font-weight: 700;
  color: #2B344F;
  font-size: 17px;
  line-height: 36px;
  margin: 0 15px;
  border-radius: 6px;
  user-select: none;
  text-align: center;
  --webkit-user-select: none;
  background-size: 100% 0px;
  transition: background-size 0.125s ease-in-out;
}

nav.top-nav span#account {
  min-width: 60px;
  min-height: 36px;
  text-decoration: none;
  font-weight: 700;
  background-color: #465572;
  color: white;
  font-size: 17px;
  line-height: 36px;
  margin: 0 15px;
  border-radius: 6px;
  user-select: none;
  text-align: center;
  --webkit-user-select: none;
  background-size: 100% 0px;
  transition: background-size 0.125s ease-in-out;
  border-radius: 18px;
}

nav.top-nav span#account:hover {
  background-color: rgba(43,52,79,.9);
  cursor: pointer;
}

nav.top-nav a:hover, nav.top-nav a.active {
  background-image: linear-gradient(120deg, rgba(58, 85, 140, 0.75) 0%, rgba(121, 153, 217, 0.85) 100%);
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: 0 90%;
  cursor: pointer;
}

nav.top-nav a.home-link {
  font-size: 20px;
  font-weight: 200;
  margin-left: 0;
  margin-right: 5px;
}

nav.top-nav .expander {
  flex: 1;
}

nav.top-nav #beta {
  font-size: 9pt;
  min-height: 36px;
  font-weight: 700;
  font-family: var(--impact-font);
  text-transform: uppercase;
}

nav.top-nav .menu-wrapper {
  min-height: 36px;
  margin: auto;
  display: flex;
  align-items: center;
}

nav.top-nav .admin-link.btn {
  position: relative;
}

nav.top-nav #admin-count {
  position: absolute;
  background-color: tomato;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8pt;
  color: black;
  bottom: -2px;
  right: -11px;
  opacity: 0.9;
  box-shadow: rgb(60 66 87 / 12%) 0px 7px 14px 0px, rgb(0 0 0 / 12%) 0px 3px 6px 0px;
}

.btn.login-link,
.btn.logout-link {
  border-radius: 18px;
  background-color: #465572;
  color: #FFF;
  padding: 0px 16px;
  width: 120px;
  background-image: none !important;
  transition: background-color 0.175s ease-in-out;
}

.btn.login-link:hover,
.btn.logout-link:hover {
  background-color: rgb(43, 52, 79, 0.9);
}

/* Remove default bullets */

ul.big-list li::before {
  font-family: 'Font Awesome 5 Free';
  content: "\f35a";
  color: var(--hero-orange);
  font-weight: 600;
  margin-right: 10px;
}

main.content section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-bottom: 3px solid #9eadbf;
  color: rgb(52, 68, 88);
  padding: 25px;
}

main.content section.no-border {
  border-bottom: 0px;
}

main.content section.page {
  display: block;
  background-color: white;
  border-radius: 15px;
  margin: 2rem 6rem 6rem 2rem;
  padding: 2rem 5rem 5rem 5rem;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
}

main.content section.page .content-wrapper {
  background-color: white;
}

main.content section.page a {
  color: var(--hero-orange);
  text-decoration: none;
  font-weight: 600;
}

main.content section.page a:hover {
  text-decoration: underline;
}

main.content .column-wrapper {
  display: flex;
  width: 90vw;
  max-width: 1920px;
  min-height: 500px;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
}

main.content .column-wrapper.slim {
  min-height: 300px;
  padding-left: 45px;
  padding-right: 35px;
}

main.content section .column {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
  z-index: 1;
  justify-content: center;
  font-size: 17px;
  font-weight: 600;
  flex-wrap: wrap;
  width: calc(100% * (1/2) - 10px - 1px);
}

main.content section .column.hero {
  min-width: 360px;
}

main.content section .column h1,
h2 {
  margin: 0;
  line-height: 120%;
  font-weight: 600;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


main.content section .column h1.tertiary,
h2.tertiary {
  margin-left: 1.0em;
}

main.content section .two-columns {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 40px 0px;
  z-index: 1;
  justify-content: center;
}

main.content section .column p,
ul.big-list {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.6em;
}

ul.big-list li {
  line-height: 2.5em;
}

main.content section .column p.slim {
  max-width: 350px;
  text-align: left;
}

main.content section .column ul.big-list {
  padding-inline-start: 48px;
  min-width: 430px;
}

main.content section .section-header {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 1em 0 1em 0;
}

#beta {
  font-size: 12pt;
  vertical-align: baseline;
}




@media only screen and (max-width: 980px) {
  #page-heading{
    padding-top: 20px;
  }
  #page-heading.profile {
    padding: 0 15px;
    padding-top: 77px;
  }
  #page-heading-title h1,
  .currentUser #page-heading-title h1,
  .self #page-heading-title h1{
    /*visibility: hidden !important;*/
    /*display: none !important;*/
    width: 100%;
    margin-left: 0.5em;
  }
  .project-list-card.horizontal{
    margin-bottom: 10px;
  }
  .top-nav {
    padding: 0 !important;
  }

  nav.top-nav a:hover {
    background-image: linear-gradient(120deg, rgba(200, 200, 225, 0.75) 0%, rgba(225, 225, 255, 0.75) 100%);
  }
  nav.top-nav .expander {
    display: none;
  }
  nav .top-nav-wrapper {
    justify-content: space-between;
  }
  .top-nav-logo-wrapper.visible .btn {
    margin-top: 20px;
    color: #FFF;
  }
  .top-nav-logo-wrapper.collapsed .btn {
    display: none;
  }
  .top-nav-logo-wrapper .menu-icon {
    display: unset;
    margin: 15px;
    font-size: 22pt;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .top-nav-logo-wrapper .menu-icon:hover {
    opacity: 0.7;
  }
  .top-nav-logo-wrapper.collapsed .menu-wrapper {
    position: absolute;
    height: 100vh;
    width: 40%;
    top: 0px;
    right: -500px;
    transition: 1s;
  }
  .top-nav-logo-wrapper.visible .menu-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #2b344fc9;
    height: 100vh;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    margin-top: 55px;
    transition: 0.4s;
  }
  nav.top-nav span#account {
    margin-top: 20px;
  }
  .post-item {
    margin: 15px 0;
  }
  .single-post-page .post-item {
    margin: 15px;
  }
  .post-item ul.tags {
    width: 280px;
  }
  .post-item .post-icon .play-icon,
  .post-item .post-icon-art .play-icon {
    padding: 55px;
  }
}

@media only screen and (max-width: 550px) {
  #page-heading.profile { padding: 77px 12px 0 12px !important; }
  #page-heading .user-card {
    margin-left: auto;
    margin-right: auto;
  }
  #page-heading .user-card .user-info { padding-left: 0; }
  #page-heading .user-card  h2 { font-size: 14pt; }
  .user-card .profile-picture-wrapper, .user-card .user-info,
  .user-card .user-heading, .user-card .profile-picture {
    margin: auto;
    text-align: center;
  }
  .user-card .unfollow-btn, .user-card .follow-btn {
    margin: auto;
    min-width: 70%;
  }
  .user-list-card .unfollow-btn, .user-list-card .follow-btn {
    margin: unset;
    min-width: unset;
  }
  h1 {
    font-size: 28pt;
  }
  #page-heading-title\ admin {
    margin: 40px 0 !important;
    justify-content: space-around !important;
  }
  .admin.content .tabs {
    margin: 5px;
  }
  #discover-main-content {
    max-width: unset;
  }
  #discover-main-content .post-item, .profile-content .post-feed .post-item {
    margin: 0;
    margin-bottom: 20px;
  }
  .scroll-top { 
    display: none; 
  }
  button #save-text {
    display: none;
  }
  .profile-content {
    max-width: unset;
    width: 96%;
  }
  .profile-content .nav-container {
    display: block;
    padding: 15px 0 0 0 !important;
    margin: auto;
  }
  .profile-content .nav-container .discover-nav-alignment {
    justify-content: center !important;
  }
  .nav-container .discover-nav-alignment .content-tabs .content-nav {
    min-width: 70px;
    display: flex;
  }
  .post-feed {
    padding: 35px 10px !important;
  }
  .profile-content, #discover-main-content,
  .project-list-card.horizontal {
    max-width: 96vw !important;
  }
  nav.top-nav a {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 320px) {
  .post-item .post-info{
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
  .post-item .post-body {
    margin-bottom: 15px;
  }
}
