/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/



/***********/
/*  Users  */
/***********/

.profile #page-heading-title h1.secondary {
  margin-right: 0;
}

#page-heading.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 77px 50px 0 50px;
  max-width: 100vw;
  margin: 0 auto;
}

#profile-page {
  background: linear-gradient(315deg, rgb(77,126,126),rgb(70,85,114));
  background-color: var(--main-bg-color);
  padding-bottom: 10px;
}

.profile-heading {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.profile-content {
  width: 95%;
  max-width: 90vw;
  margin: 40px auto;
  border-radius: 7px;
  position: relative;
  box-shadow: var(--main-box-shadow);
  background: #E8E8E8;
}

.profile-content .post-feed {
  max-width: 100%;
  max-height: 90%;
  padding: 35px 20px;
  align-items: flex-start;
  
}

.profile-content .post-feed .post-item {
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  background-color: white;
  box-shadow: 0px 10px 5px -10px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 0%);
}

.profile-content .post-feed .post-item .post-icon {
  width: 400px;
  height: 400px;
}

.user-card {
  position: relative;
  width: 100%;
  /* height: 185px; */
  max-width: 550px;
  background-color: #E8E8E8;
  padding: 45px 17px;
  font-size: 18px;
  border-radius: 6px;
  box-shadow: rgb(60 66 87 / 12%) 0px 4px 10px 0px, rgb(0 0 0 / 12%) 0px 3px 6px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 20px 0;
}

.user-card .followers-modal-anchor {
  display: inline;
  font-weight: 700;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user-card .followers-modal-anchor:hover {
  text-decoration: underline;
  cursor: pointer;
}

.user-card .modal {
  top: 50%;
  max-width: 90%;
}

.user-list-card {
  display: flex;
  flex-direction: column;
  width: 470px;
  max-width: 100%;
  font-size: 16pt;
  max-height: 420px;
  overflow: auto;
  margin-top: 7px;
  border: 1px solid #00000057;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user-list-card a {
  color: var(--hero-color);
  text-decoration: none;
}

.user-list-card a:hover {
  text-decoration: underline;
}

.user-list-element {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 8px;
}

.user-list-element:hover {
  background-color: rgba(29, 27, 27, 0.082);
}

.user-list-element button {
  width: 100px;
  margin-left: auto;
}

.user-name {
  display: flex;
  align-items: center;
}

.user-name .profile-picture {
  margin-right: 6px;
}
.user-card .profile-picture-wrapper {
  align-self: normal;
}

.profile-overlay {
  width: 100px;
  height: 100px;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.375);
  /* Black see-through */
  color: #f1f1f1;
  transition: all 0.175s ease-in-out;
  opacity: 0;
  text-align: center;
  cursor: pointer;
  padding: 11px;
  border-radius: 20%;
  top: 45px;
  position: absolute;
}

.profile-overlay:hover {
  opacity: 1;
}

#upload-icon {
  font-size: 45px;
  opacity: 0.8;
  position: absolute;
  left: 28px;
  top: 22px;
}

.user-info {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*height: 100px;*/
  width: 75%;
  justify-content: space-between;
  align-self: normal;
}

.user-info .user-heading {
  color: var(--hero-color);
  font-size: small;
  max-width: 85%;
  word-wrap: break-word;
  vertical-align: middle;
  margin-bottom: 10px;
}

.user-info .user-heading h2 {
  display: inline
}

.user-info span#edit-profile {
  display: flex;
}

.user-info .user-edit-form {
  padding: 8px 6px;
  margin: 5px 0 15px 0;
  border: #2e303f52 1px inset;
  border-radius: 8px;
  background-color: rgba(180, 180, 180, 0.22);
  box-shadow: rgb(60 66 87 / 12%) 0px 4px 10px 0px, rgb(0 0 0 / 12%) 0px 3px 6px 0px;
  max-height: 400px;
  transition: max-height 0.15s ease-out;
}

.user-info .user-edit-form.hidden {
  max-height: 0px;
  transition: max-height 0.15s ease-out;
  display: unset !important;
  visibility: unset;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: none;
}



.form-field-wrapper {
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  font-family: var(--main-font) !important;
  color: var(--hero-color);
  font-size: 18px;
  margin-bottom: 15px;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-edit-form .text-label {
  display: inline-flex;
  align-items: center;
  color: var(--hero-color);
  font-family: var(--main-font) !important;
  font-size: 11pt;
  font-weight: 600;
  width: 100%;
}

.user-edit-form hr {
  margin-bottom: 15px;
  border-style: solid;
  flex: 1;
  height: 1px;
  border: none;
  border-top: 1px solid #2e303f66;
}

.form-field-wrapper #privacy {
  font-size: 9pt;
  font-weight: 600;
  color: #2e303fb0;
  font-family: var(--main-font) !important;
  display: flex;
  align-items: center;
  margin: 4px;

}

.form-field-wrapper #privacy .switch {
  margin-left: 5px;
}

.form-field-wrapper input#email {
  /*width: 100%;*/
  flex-grow: 1;
  margin: 0 10px 0 0;
  width: 45%;
  min-width: 100px;
  height: 32px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  font-family: var(--main-font);
  font-weight: 400;
  padding: 0.375rem 0.5rem;
  text-decoration: none;
}

.content-nav.faves .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-nav.faves .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-nav.faves .icon  #fave-star {
  color: var(--drum-color);
  font-size: 17pt;
}

#reset-button {
  text-decoration: none;
}
