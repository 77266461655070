/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/


/*
* Project list
*/

#discover-content {
  padding-bottom: 50px;
  position: relative;
  background: linear-gradient(315deg, rgb(77,126,126),rgb(70,85,114));
  background-color: var(--main-bg-color);
}

.project-list-card {
  position: relative;
  width: 505px;
  max-width: 100%;
  background-color: #E8E8E8;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 7px;
  box-shadow: var(--main-box-shadow);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: center;
  height: 560px;
  max-height: 90%;
  overflow: auto;
  margin: 0 0 50px 0;
}

.project-list-card.horizontal {
  flex-direction: row;
  width: unset;
  max-width: 90vw;
  margin: auto;
  margin-top: 10px;
  height: 275px;
  margin-bottom: 50px;
  overflow-y: hidden;
}

.project-list-card.horizontal .project-item .project-sharing {
  display: none ;
}

.project-list-card.horizontal .project-item {
  margin: 0 20px;
  overflow-y: hidden;
}

#discover-main-content .project-item .project-sharing {
  display: none !important;
}

.profile-content .project-list-card {
  width: 100%;
  height: unset;
  box-shadow: none;
  padding: 10px 20px;
  box-shadow: var(--main-box-shadow);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: unset;
  justify-content: space-around;
}

.nav-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 60px;
  padding-bottom: 15px;
  margin: 0px auto;
}

.nav-container .learn-description-wrapper {
  position: relative;
  max-width: 600px;
  display: grid;
  gap: 20px;
  margin: 0px auto 45px auto;
  text-align: center;
}

.nav-container .learn-description-wrapper .description {
  font-weight: 600;
  text-align: justify;
}

.nav-container .discover-nav-alignment {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
}

.nav-container .discover-nav-alignment .content-tabs {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 28px;
  font-weight: 400;
  max-width: 960px;
}

.nav-container .discover-nav-alignment .content-tabs .content-nav {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px;
  min-width: 80px;
  align-items: center;
  text-align: center;
}

.nav-container .discover-nav-alignment .content-tabs .content-nav:hover {
  cursor: pointer;
  background-color: rgba(180, 180, 180, 0.25);
}

.nav-container .discover-nav-alignment .content-tabs .content-nav .content-nav-label {
  margin-top: 5px;
}

.nav-container .discover-nav-alignment .content-tabs .content-nav .icon:hover {
  cursor: pointer;
}

.content-tabs.projects .btn.projects,
.content-tabs.posts .btn.posts,
.content-tabs.faves .btn.faves {
  background-color: rgba(180, 180, 180, 0.5);
}

.nav-container .discover-nav-alignment .search-container {
  overflow: hidden;
  margin-top: 14px;
}

.nav-container .discover-nav-alignment .search-container .input-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 280px;
  height: 40px;
  max-width: 100%;
  border-radius: 25px;
  border: none;
  align-items: center;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px inset;
  outline: 0;
  border-width: 0 0 2px;
}

.nav-container .search-icon {
  color: #7F8382;
  font-size: 1.2rem;
  line-height: 1.2rem;
  vertical-align: middle;
  transition: color 0.25s;
  margin-right: 5px;
}

.nav-container .search-icon:hover {
  color: #555858;
  cursor: pointer;
}

.nav-container .discover-nav-alignment .search-container .input-wrapper input {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 280px;
  height: 40px;
  max-width: 400px;
  border-radius: 25px;
  border: none;
  align-items: center;
  padding-left: 5px;
  background-color: transparent;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
}

.nav-container .discover-nav-alignment .search-container .input-wrapper input:before {
  content: "\f002";
  color: #7F8382;
  font-size: 1.2rem;
  line-height: 1.2rem;
  vertical-align: middle;
  transition: color 0.25s;
  margin-right: 5px;
}

.nav-container .discover-nav-alignment .search-container .input-wrapper input::-webkit-input-placeholder {
  color: #7F8382;
}

.nav-container .discover-nav-alignment .search-container .input-wrapper input:focus {
  outline-width: 0;
  outline-style: none;
}

.nav-container .discover-nav-alignment .search-container .input-wrapper i {
  color: #7F8382;
  font-size: 1.2rem;
  line-height: 1.2rem;
  vertical-align: middle;
  transition: color 0.25s;
  margin-right: 5px;
}

.nav-container .discover-nav-alignment .search-container .input-wrapper i:hover {
  color: black;
}

.nav-container .discover-nav-alignment .search-container .search-results {
  display: none;
  position: absolute;
  width: 280px;
  max-height: 400px;
  margin-top: 5px;
  padding: 5px 0px 5px 0px;
  border-radius: 20px 20px 30px 30px;
  background-color: #FFF;
  color: #A6A6A6;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 9;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item {
  display: flex;
  height: 35px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-transform: lowercase;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item:focus,
.nav-container .discover-nav-alignment .search-container .search-results .search-item:hover {
  background-color: #F1F1F1;
  cursor: pointer;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-text-snippet {
  width: 50%;
  height: 25px;
  line-height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-type {
  width: 30%;
  height: 25px;
  line-height: 25px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 5px;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-icon-wrapper {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 3px;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-icon-wrapper .result-icon {
  width: 16px;
  height: 16px;
}

/*.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-icon-wrapper .result-icon.glossary {
  background-color: #FFF;
  color: #FFF;
  -webkit-mask-image: url("../resources/glossary.svg");
  mask-image: url("../resources/glossary.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-origin: content-box;
  -webkit-mask-origin: content;
}
*/
.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-icon-wrapper .result-icon.posts {
  background-color: #FFF;
  color: #FFF;
  -webkit-mask-image: url("../resources/post-open.svg");
  mask-image: url("../resources/post-open.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-origin: content-box;
  -webkit-mask-origin: content;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-icon-wrapper .result-icon.guidebook {
  background-color: #FFF;
  color: #FFF;
  -webkit-mask-image: url("../resources/project-code.svg");
  mask-image: url("../resources/project-code.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-origin: content-box;
  -webkit-mask-origin: content;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-icon-wrapper.glossary {
  background-color: #F263D3;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-icon-wrapper.posts {
  background-color: #55C5FF;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item .result-icon-wrapper.guidebook {
  background-color: #FF7D00;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item.glossary-item {
  color: #ef4565;
}

.nav-container .discover-nav-alignment .search-container .search-results .search-item.tutorial-item {
  color: #56758e;
}

.nav-container .discover-nav-alignment .search-container li {
  line-height: 1em;
}

.nav-container .discover-nav-alignment .search-container li:before {
  content: "";
}

.nav-container .icon {
  width: 45px;
  height: 45px;
  padding: 5px;
  cursor: default;
  border-radius: 50%;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
}

.nav-container .posts-section {
  background-image: url('../resources/post-open.svg');
}

.nav-container .guidebook-section {
  background-image: url('../resources/project-code.svg');
  opacity: 0.9;
}

.search-menu {
  max-width: 100%;
}

.search-text {
  width: 100%;
  padding-left: 60px;
  color: var(--nav-color);
  opacity: 0.75;
  font-size: 10pt;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.search-text button {
  height: 21px;
  font-size: 10pt;
  margin-left: 5px;
}

.search-text .clear-search:hover {
  opacity: 0.7;
  cursor: pointer;
}

.sort-menu {
  text-align: right;
  font-size: 14px;
  font-weight: 700;
  color: #999;
  line-height: 2em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  position: relative;
  margin-top: 10px;
  text-transform: uppercase;
}

.sort-criteria:hover {
  text-decoration: underline;
  cursor: pointer;
}

.sort-menu i {
  margin-left: 4px;
}

.sort-menu .drop-menu {
  left: unset;
  right: 0;
}

.sort-menu .drop-menu .menu-item {
  padding-left: 1em;
}

.drop-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #0001;
  border-radius: 8px;
  padding: 8px 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  box-shadow: 0px 3px 5px #0005;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.drop-menu.hidden {
  display: none;
}

.drop-menu:hover {
  cursor: pointer;
}

.drop-menu.terminal {
  max-height: 70vh;
  overflow-y: auto !important;
}

.drop-menu li.menu-item {
  line-height: 150%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: #3C4E60;
  outline: none;
  border: none;
  padding: 5px 20px 5px 10px;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.drop-menu li.menu-item:hover {
  background: rgba(0, 0, 0, 0.15);
}

.drop-menu li.menu-item:active {
  background: rgba(0, 0, 0, 0.2);
}

.drop-menu li.menu-item.highlight {
  background: rgba(0, 0, 0, 0.085);
}

.menu-item:hover>.drop-menu {
  display: flex;
  left: 143px;
  top: -10px;
}

.drop-menu.created_desc .menu-item.created_desc,
.drop-menu.created .menu-item.created,
.drop-menu.num_favorites_desc .menu-item.num_favorites_desc {
  background: rgba(0, 0, 0, 0.085);
  font-weight: bold;
}

.post-feed {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  overflow: hidden auto;
}
.content.feed .post-feed {
  background: linear-gradient(315deg, rgb(77,126,126),rgb(70,85,114));
  background-color: var(--main-bg-color);
}

.post-count {
  width: 100%;
  color: var(--nav-color);
  opacity: 0.75;
  font-size: 11pt;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  padding-bottom: 20px;
}

.post-feed:after{
  content: "";
  flex: auto;
  width: 100%;
}

.feed .post-feed{
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

#discover-main-content {
  width: 95%;
  max-width: 90vw;
  margin: 10px auto;
  border-radius: 7px;
  position: relative;
  box-shadow: var(--main-box-shadow);
  background: #E8E8E8;
}

#discover-content .post-feed {
  padding-bottom: 50px;
  padding-top: 10px;
  align-items: flex-start;
}

.project-feed {
  width: 95%;
  margin: auto;
  padding-top: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#discover-content .project-feed .project-sharing {
  display: none;
}

ul.big-list {
  list-style: none;
}

