/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/


/***********/
/*  Admin  */
/***********/

.content.admin {
  background: var(--background-pattern);
}

#page-heading-title\ admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 40px;
  flex-wrap: wrap;
}

#page-heading-title\ admin h1 {
  margin: 0;
  color: #2B344F;
}

.admin.content .tabs span {
  padding: 5px;
  background-color: #E4E4E4;
  border: 3px transparent solid;
  transition: 0.5s all;
  font-family: var(--main-font);
  font-weight: bold;
  font-size: 13px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.admin.content .tabs span#posts {
  border-radius: 5px 0 0 5px;  
}

.admin.content .tabs span:hover {
  transition: 0.5s all;
  opacity: 0.8;
  cursor: pointer;
}

.admin.content .tabs span#posts.selected {
  background-color: var(--sequencer-color);
  color: white;
}

.admin.content .tabs span#library.selected {
  background-color: var(--definitions-color);
  color: white;
}

.admin.content .tabs span#flags.selected {
  background-color: var(--error-color);
  color: white;
}

.admin.content .tabs span#projects.selected {
  background-color: var(--drum-color);
  color: white;
}

.admin.content .tabs span#posts:hover {
  border: 3px var(--sequencer-color) solid;
}

.admin.content .tabs span#library:hover {
  border: 3px var(--definitions-color) solid;
}

.admin.content .tabs span#flags:hover {
  border: 3px var(--error-color) solid;
}

.admin.content .tabs span#projects:hover {
  border: 3px var(--drum-color) solid;
}

.admin.content .tabs span#projects {
  border-radius: 0 5px 5px 0;  
}

.admin-table-card {
  position: relative;
  width: 90%;
  max-width: 100%;
  min-height: unset;
  background-color: #E8E8E8;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 7px;
  box-shadow: var(--main-box-shadow);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: center;
  height: 560px;
  max-height: 70%;
  overflow: auto;
  margin: auto;
  font-family: var(--main-font) !important;
}

.admin-table-card.post-approval {
  background: none;
  box-shadow: none;
  width: unset;
  max-height: unset;
  margin: 0;
  min-height: unset;
  padding: 0 10px;
}

.admin-table-card span.name {
  min-width: 385px;
  display: flex;
  align-items: center;
}

.admin-table-card span.author {
  width: 200px;
}

.admin-table-card span.actions {
  width: 255px;
}

.admin-table-card .project-item span.actions {
  width: 215px;
}
.admin-table-card span.reason {
  width: 200px;
}

.admin-table-card span.expand {
  width: 50px;
  text-align: center;
}

.admin-table-card #table-header {
  font-weight: bold;
  border-bottom: 1px var(--nav-color) solid;
}

.admin-table-card .row {
  min-height: 40px;
  width: 100%;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.admin-table-card .project-item {
  min-height: 75px;
}

.admin-table-card .post-item {
  margin: 10px 0;
}

.admin-table-card .details .post-item { 
  margin: auto !important;
}

.post-approval .post-item .tags {
  display: none;
}

.post-approval .post-item .admin-actions {
  position: absolute;
  bottom: 0px;
  left: 2%;
  width: 96%;
  display: flex;
  justify-content: space-evenly;
  height: 36px;
  border-top: 1px #4142514a solid;
}

.post-approval .post-item .admin-actions button {
  width: 45%;
  background-color: unset;
  color: #414251c7;
  display: flex;
  justify-content: center;
}

.post-approval .post-item .admin-actions button#approve:hover {
  color: var(--drum-color);
}

.post-approval .post-item .admin-actions button#ignore:hover {
  color: var(--error-color);
}

.library-item .play-icon path {
  transform: scale(.5) translate(50%, 50%);
}

.library-item .play-icon  {
  display: flex;
  align-content: center;
  justify-content: center;
}

.admin-table-card #new-label {
  color: var(--drum-color);
}

.admin-table-card .author {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.admin-table-card .name  {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.admin-table-card .author a {
  text-decoration: none;
  color: var(--header-bg-color);
}

.admin-table-card.project-container .name {
  white-space: unset;
  cursor: default;
}

.admin-table-card .author a:hover {
  text-decoration: underline;
}

.admin-table-card .actions {
  display: flex;
  align-items: center;
}

.admin-table-card input, .admin-table-card textarea {
  margin: 5px 10px 5px 0;
  width: 55%;
  min-width: 100px;
  height: 32px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  font-family: var(--main-font);
  font-weight: 400;
  padding: 0.375rem 0.5rem;
  text-decoration: none;
}

.admin-table-card textarea {
  height: 100px;
  resize: none;
}

.admin-table-card input:read-only, .admin-table-card textarea:read-only {
  cursor: no-drop;
  background-color: rgb(85 85 85 / 22%);
  border-color: darkgrey;
}


.expand {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.expand:hover {
  cursor: pointer;
  opacity: 0.7;
}

.admin-table-card .details {
  display: none;
}

.admin-table-card .details.expanded {
  display: flex;
  width: 97%;
  padding: 20px;
  background: #fefefec2;
  flex-direction: column;
  align-items: flex-start;
}

.admin-table-card .details .row {
  height: unset;
  font-size: 12pt;
  line-height: 1;
  min-height: 24px;
  color: var(--header-bg-color);
}

.admin-table-card div.details.expanded > span > hr {
  margin: 15px 0;
}

.admin-table-card .details pre {
  font-family: monospace;
  white-space: pre;
  font-size: 90%;
  background-color: #555;
  padding: 2em;
  color: #efefef;
  max-width: 100%;
  min-width: 90%;
  overflow: auto;
  margin: auto;
  max-height: 100%;
  overflow: auto;
}

.admin-table-card .row .post-item {
  margin: auto;
}

.admin-table-card .post-item .options, 
.admin-table-card .post-item .post-toolbar, 
.admin-table-card .post-item .reactions-tray {
  display: none;
}
