/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/


/***********/
/*  Posts  */
/***********/

.post-item {
  position: relative;
  width: 400px;
  min-width: 220px;
  max-width: 100%;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  background-color: rgba(215, 215, 215, 0.2);
  background: white;
  box-shadow: 0px 10px 5px -10px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0);
  margin: 10px 0;
  width: 385px;
  margin: 25px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.post-item .admin-actions button {
  display: none;
}

.post-item .post-description {
  color: rgba(52, 68, 88, 0.9);
  min-height: 25px;
  max-height: 100px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
  line-height: 17px;
  font-size: 11.5pt;
  font-weight: normal !important;
  margin-top: 17px;
  margin-bottom: 12px;
}

.post-item .post-description #tag_text {
  text-decoration: none;
}

.post-item .post-description #tag_text:hover {
  text-decoration: underline;
}

.post-item:hover {
  box-shadow: 0px 15px 5px -10px rgba(50, 50, 93, 0.25), 0 30px 65px -30px rgba(0, 0, 0, 0);
}

.post-item .post-icon {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../resources/project_art.svg");
}

.post-item .post-icon {
  width: 510px;
  max-width: 100%;
  height: 0 !important;
  padding-bottom: 100% !important;
}

.post-icon canvas,
.post-icon-art canvas {
  visibility: hidden;
}

.playing canvas {
  visibility: visible !important;
}

.post-item canvas {
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 500;
}

.post-item .post-icon:hover .play-icon,
.post-item .post-icon-art:hover .play-icon {
  visibility: visible;
  opacity: 0.5;
  color: white;
}

.post-item .post-icon .play-icon {
  visibility: hidden;
  background-color: #0009;
  opacity: 0;
  transition: opacity 0.5s;
  text-shadow: 3px 3px 5px #000a;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  background-color: #0009;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50pt;
}

.post-item .post-summary {
  display: flex;
  gap: 5px;
  height: 150px;
  align-self: center;
  flex-wrap: wrap;
}

.post-item .post-summary .post-body {
  font-style: normal;
  font-size: 15px;
  line-height: 23px;
  font-weight: 600;
  color: var(--hero-color);
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  text-decoration: none;
}

.post-item .post-summary .post-body {
  width: 100%;
  font-size: larger;
}

.post-item .post-body .edit-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.post-item.editing {
  padding-bottom: 0;
}

.post-item.editing .tags, .post-item.editing .post-toolbar, .post-item.editing .reactions-tray {
  display: none;
}

.post-item .post-body textarea {
  width: 100%;
  resize: none;
  height: 80px;
  font-size: 11pt;
  font-weight: 500;
  font-family: var(--main-font) !important;
  padding: 4px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0e6e144;
  transition: border-color 0.15s ease-in-out;
}

.post-item .post-body textarea:focus {
  border-color: var(--text-color);
  transition: border-color 0.15s ease-in-out;
}

.post-item .post-body #character-counter {
  font-size: 8pt;
  color: var(--nav-color);
  opacity: 0.7;
  margin-top: 2px;
  align-self: flex-start;
}

.post-item .edit-actions {
  flex-grow: 1;
  display: inline-flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.post-item .post-toolbar {
  position: relative;
  bottom: 0;
  min-height: 40px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  background-color: rgba(255, 255, 255, 0.5);
}

.post-item .post-toolbar .button-group {
  min-width: 40%;
  min-height: 40px;
  margin-left: auto;
  display: inline-flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-wrap: nowrap;
}

.post-item .post-toolbar .button-group button {
  margin: 0 2px;
  width: 42px;
}

.post-item .post-toolbar .project-link {
  text-decoration: none;
}

.post-item .post-toolbar .btn-icon {
  font-size: 14px;
}

.post-item .post-toolbar .post-like-button {  
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100%;
  font-size: 12px;
  font-weight: 600;
  color: rgba(52, 68, 88, 0.5);
  background-color: rgba(215, 215, 215, 0.25);
  border: rgba(215, 215, 215, 0.25) 1px outset;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.post-item .post-toolbar .post-like-button:hover{  
  cursor: pointer;
  color: rgba(52, 68, 88, 0.85);
  background-color: rgba(245, 245, 245, 0.5);
  box-shadow: var(--main-box-shadow);
}

.post-item .post-toolbar .post-like-button {
  border-radius: 5px;
}

.post-like-button.liked,
.post-like-button.liked .btn-icon {
  color: #86c44e !important;
}

.post-item .post-toolbar .post-reaction-button {  
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100%;
  margin: 0;
  border: none;
  font-size: 12px;
  font-weight: 600;
  color: var(--error-color);
  background-color: rgba(215, 215, 215, 0.25);
  border: rgba(215, 215, 215, 0.25) 1px outset;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.post-reaction-button #add-reaction {
  color: rgba(52, 68, 88, 0.5);
  font-size: 14pt;
}

.post-item .reactions-tray {
  margin-top: 5px;
  margin-right: -2px;
  display: flex;
  justify-content: flex-end;
}

.post-item .reactions-tray .tray-element {
  border: 1px rgba(52, 68, 88, 0.75) outset; 
  border-radius: 10px;
  width: 44px;
  padding: 0 3px;
  margin: 0 2px;
  height: 20px;
  font-family: var(--main-font);
  color: rgba(52, 68, 88, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  font-size: 10pt;
}

.post-item .reactions-tray .tray-element .icon-wrapper {
  display: flex;
}

.post-item .reactions-tray .tray-element .tray-icon {
  height: 16px;
}

.post-item .post-toolbar .post-reaction-button:hover{  
  cursor: pointer;
  color: rgba(52, 68, 88, 0.85);
  background-color: rgba(245, 245, 245, 0.5);
  box-shadow: var(--main-box-shadow);
}

.post-item .post-toolbar .post-reaction-button {
  border-radius: 5px;
}

.options {
  position: absolute;
  color: rgba(52, 68, 88, 0.5);
  right: 0;
  top: 0;
  padding: 15px;
}

.options:hover {
  cursor: pointer;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  color: rgba(52, 68, 88, 0.85);
}

.post-item .options #ellipsis-icon {
  visibility: hidden;
}

.post-item:hover .options #ellipsis-icon {
  visibility: visible;
}

.options .options-dropdown {
  position: absolute;
  left: -100%;
  right: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 120px;
  background-color: #FFF;
  margin: 3px;
  padding: 4px;
  border-radius: 6px;
  box-shadow: rgb(60 66 87 / 12%) 0px 4px 10px 0px, rgb(0 0 0 / 12%) 0px 3px 6px 0px;
  z-index: 1000;
}

.options hr {
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  border: 0.1px solid #3D4D60;
  border-top: 0px;
  background-color: #3D4D60;
  opacity: 0.25;
}

.options .options-dropdown .options-row {
  height: 22px;
  width: 100%;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.options-row svg {
  margin-right: 5px;
}

.options-row.delete svg {
  color: var(--error-color);
}

.options-row:hover {
  opacity: 0.8;
  background-color: #3d4d6021;
}

.options .hidden {
  visibility: hidden;
}

.options #report-flag {
  font-size: 9pt;
  vertical-align: middle;
}

.report-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.report-body select,
.report-body textarea {
  flex-grow: 1;
  margin: 5px 10px 5px 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  font-family: var(--main-font);
  font-weight: 400;
  padding: 0.375rem 0.5rem;
  text-decoration: none;
}

.report-body select.error {
  border-color: rgb(205, 92, 92);
  border-width: 2px;
  transition: border-color 0.15s ease-in-out, border-width 0.15s ease-in-out;
}

.post-item .post-body {
  font-weight: bold;
  color: #333;
  font-size: larger !important;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  flex: 1;
  margin-bottom: 15px;
}

.post-item .post-body {
  font-size: xx-large !important;
  padding-top: 8px;
}

.post-item.deleted {
  display: none;
}

.music-info {
  visibility: visible;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  bottom: 26px;
  left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  color: rgba(52, 68, 88, 0.5);
}

.music-info.author {
  /*color: #333;*/
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.3em;
  user-select: none;
  flex: 1;
}

.music-info.author a {
  color: rgba(52, 68, 88, 0.5);
}

.post-item .post-body {
  text-decoration: none;
}

.post-like-button.liked #post-like-count {
  color: #86c44e !important;
}
.post-like-button:disabled {
  background-color: #ccc !important;
  color: #3d4d60 !important;
  outline: none !important;
  cursor: not-allowed !important;
}
.post-like-button:disabled:hover {
  background-color: #ccc !important;
  color: #3d4d60 !important;
  outline: none !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
}

.post-item .post-header {
  display: flex;
  justify-content: flex-start;
  margin: 2px 0;
}

.post-item .post-header .profile-picture {
  margin-right: 6px;
}

.post-item .post-info {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  max-width: 85%;
  min-height: 35px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  color: rgba(52, 68, 88, 0.5);
  overflow: visible;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  margin: 3px 0;
}

.post-item .post-info a {
  color: rgba(52, 68, 88, 0.5);
}

.post-item .post-info .project-link {
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.post-item ul.tags {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  opacity: 0.85;
  position: relative;
  /*position: absolute;
  bottom: 9px;
  height: 40px;
  width: 320px;*/
  max-width: 60%;
}

.post-item .tags-scroll-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 4px;
  padding-right: 20px;
}

.post-item ul.tags:after {
  content: "";
  width: 28px;
  position: absolute;
  height: 40px;
  right: 0;
  top: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.post-item .tags-scroll-container:hover {
  overflow-x: scroll;
  margin-bottom: 0px;
  scrollbar-width: thin;
}

.post-item .tags-scroll-container a {
  text-decoration: none;
}

.post-item ul.tags li {
  display: inline;
  color: white;
  font-size: 12px;
  font-weight: bold;
  background-color: var(--bass-color);
  padding: 0.4em 0.5em;
  margin-left: 5px;
  line-height: 150%;
  height: 20px;
  white-space: nowrap;
  text-decoration: none;
}

.post-item ul.tags a:hover {
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.1s;
}

.post-item .tags-scroll-container::-webkit-scrollbar {
  height: 4px;
}

.post-item .tags-scroll-container::-webkit-scrollbar-track {
  height: 4px;
}

.post-item .tags-scroll-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid #414251ce;
}

.single-post-page {
  padding-top: 90px;
  padding-bottom: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.single-post-page .post-item.deleted {
  display: unset;
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events:none;
}

.single-post-page .post-item.deleted .play-icon {
  display: none;
}
