/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/


/****************/
/*   Projects   */ 
/****************/


.project-item.card {
  position: relative;
  justify-content: start;
  gap: 55px;
  width: 400px;
  min-width: 220px;
  height: 125px;
  max-height: 220px;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  background-color: white;
  box-shadow: 0px 10px 5px -10px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0);
  margin: 10px 0;
  max-width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.project-item.large {
  width: 725px;
  min-height: 240px;
}

.project-item.featured {
  width: 250px;
  height: 250px;
  overflow: hidden;
}

.project-item.large .project-description {
  color: rgba(52, 68, 88, 0.5);
  height: 68px;
  text-overflow: ellipsis;
  margin: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
  line-height: 17px;
  font-size: 11.5pt;
}

.project-item.card:hover {
  box-shadow: 0px 15px 5px -10px rgba(50, 50, 93, 0.25), 0 30px 65px -30px rgba(0, 0, 0, 0);
}

.project-item .project-icon {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../resources/project_art.svg");
}

.project-item.large .project-icon {
  width: 90px;
  height: 90px;
}

.project-item.featured .project-icon {
  width: 100%;
  height: 100%;
}

.project-item .project-icon:hover .play-icon,
.project-item .project-icon-art:hover .play-icon {
  visibility: visible;
  opacity: 0.5;
  color: white;
}

.project-item .project-icon .play-icon,
.project-item .project-icon-art .play-icon {
  visibility: hidden;
  background-color: #0009;
  opacity: 0;
  transition: opacity 0.5s;
  text-shadow: 3px 3px 5px #000a;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #0009;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50pt;
}

.project-icon-art {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  position: relative;
}

.project-item.large .project-icon-art {
  width: 182px;
  height: 182px;
}

.project-item.featured .project-icon-art {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../resources/project_art.svg");
}

.project-icon-art img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-item .project-icon.playing {
  padding-left: 0;
  background-image: none;
  color: white;
}

.project-item .project-summary {
  display: flex;
  gap: 5px;
  height: 150px;
  align-self: center;
  flex-wrap: wrap;
}

.project-item .project-summary .project-name {
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  color: var(--hero-color);
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  text-decoration: none;
}

.project-item.large .project-summary .project-name {
  font-size: 15px;
  line-height: 23px;
  font-weight: 600;
  padding-left: 10px;
  font-size: larger;
}

.project-item .project-name-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  font-size: 13pt;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-item.featured .project-name {
  font-size: 15pt;
}

.project-item.featured .project-name.overflowing,
.project-item .project-name-text.overflowing {
  text-overflow: ellipsis;
  transform: translateX(0);
  transition: 4s;
}

.project-item.featured .project-name.overflowing:hover {
  white-space: nowrap;
  transform: translateX(calc(200px - 100%));
  text-overflow: unset;
  overflow: visible;
}

.project-item .project-name-text.overflowing:hover {
  white-space: nowrap;
  transform: translateX(calc(35px - 100%));
  text-overflow: unset;
  overflow: visible;
}

.project-item .project-summary .sort-criteria {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  bottom: 10px;
  left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  color: rgba(52, 68, 88, 0.5);
}

.project-item .project-sharing {
  font-weight: 800;
  font-size: 11px;
  line-height: 20px;
  height: 20px;
  color: #FFF;
  border-radius: 5px;
  text-align: center;
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: var(--piano-color);
}

.project-item .project-sharing.collaborative {
  min-width: 65px;
}
.project-item .project-sharing.private {
  background-color: var(--bass-color);
  min-width: 60px;
}

.project-item .project-sharing.viewonly {
  background-color: var(--drum-color);
  min-width: 75px;
}
.project-item .project-sharing.public {
  background-color: var(--sequencer-color);
  min-width: 75px;
}
.project-item .project-sharing.featured {
  background-color: var(--definitions-color);
  min-width: 75px;
}
.project-item.row .project-sharing {
  min-width: 75px !important;
  margin-right: 5px;
  margin: 0 10px 0 10px;
  z-index: 100;
}

.project-item .project-sharing.hidden {
  display: none;
}

.project-item .project-details {
  display: block;
}

.project-item .project-details.hidden {
  visibility: hidden;
}

.project-item .project-toolbar {
  visibility: visible;
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
}

.project-item .project-name {
  font-weight: 900;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.3em;
  user-select: none;
  flex: 1;
}
.project-item .project-name-link {
  text-decoration: none;
  color: #333;
  font-size: 13pt !important;
  font-style: normal;
  line-height: 19px;
  font-weight: 600;
  color: var(--hero-color);
}

.project-item.row .project-name-link {
  font-weight: 200 !important;
  max-width: 215px;
}

.project-item .project-name-link:hover {
  text-decoration: underline;
}

.project-item .project-name .author,
.project-item.featured .author {
  font-weight: normal;
  display: block;
  width: 100%;
  flex-grow: 1;
  font-size: 11pt;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.3em;
  user-select: none;
  flex: 1;
}

.project-item.featured .author a,
.project-item .project-name .author a {
  text-decoration: none;
  color: #333;
}

.project-item.featured .author a:hover,
.project-item .project-name .author a:hover {
  text-decoration: underline;
}

.project-item .project-name {
  text-decoration: none;
}

.project-item .project-sharing {
  color: var(--piano-color);
  font-weight: bold;
  font-size: 11px;
  margin-left: 3px;
  margin-top: 3px;
  min-width: none;
}

.project-item .project-sharing {
  font-weight: 800;
  font-size: 11px;
  line-height: 20px;
  height: 20px;
  color: #FFF;
  border-radius: 5px;
  text-align: center;
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: var(--piano-color);
}

.project-item.featured .project-info {
  display: flex;
  flex-direction: column;
}

.music-info {
  visibility: visible;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  bottom: 26px;
  left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  color: rgba(52, 68, 88, 0.5);
}

.project-item .creation-date {
  visibility: visible;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  bottom: 10px;
  left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  color: rgba(52, 68, 88, 0.5);
}

.project-item.large ul.tags {
  visibility: visible;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  position: absolute;
  bottom: 0;
  left: 170px;
  max-width: 355px;
  overflow: hidden;
  opacity: 0.85;
}

.project-item.large ul.tags li {
  display: inline;
  color: white;
  font-size: 14px;
  font-weight: bold;
  background-color: var(--bass-color);
  padding: 0.1em 0.5em;
  margin: 0 10px 10px 0;
  line-height: 150%;
}

.project-item:hover .options #ellipsis-icon {
  visibility: visible;
}

.project-item .options #ellipsis-icon {
  visibility: hidden;
}